<template>
  <div class="pl-1 pr-1">
    <b-row>
      <b-col>

          <b-button @click="verifyTYC()" variant="blue-button" class="float-right mb-1 blue-button">
            <feather-icon
              icon="PlusCircleIcon"
              size="16"
            />
            {{$t('invitation.add_content')}}
          </b-button>
      </b-col>
    </b-row>

    <b-col v-if="items.length === 0">
      <div class="no-content text-center">{{$t('invitation.no_content')}}</div>
    </b-col>

    <b-col v-else class="border">
      <b-row class="p-1">
        <b-col v-for="(item, index) in itemsFiltered()" :key="index" class="border my-1 p-0 col-12" :perPage="per_page" :currentPage="current_page">
          <div class="d-flex p-1 cursor-pointer" @click="showDetails(item.actions)">
            <b-img :class="`${item.content_type.network !== 'twitter' ? 'icon-size' : 'icon-size-x'}`" :src="utils.getImageNetworkColor(item.content_type.network)"/>
            <div class="ml-1">
              <strong>
                {{utils.capitalize(item.content_type.network)}} -
                {{getContentType(item.content_type)}}
                <feather-icon icon="CheckCircleIcon" class="text-success" v-if="item.actions.url_info !== null || item.actions.image_content !== null"/>
              </strong>
              <span class="d-block mt-1">
                {{$t('campaigns.date_publish')}}: {{formatDate(item.published_date)}},  
                {{$t('campaigns.last_update')}}: {{formatDate(item.last_update, true)}}
              </span>
            </div>
          </div>
          <div class="button-box-color-gray p-1 border-top">
            <b-button size="sm" class="mr-1 mb-1 mb-sm-0" variant="outline-secondary" @click="showDetails(item.actions)">
              <feather-icon icon="EditIcon" size="18"/>
              {{$t('invitation.edit')}}
            </b-button>
            <b-button size="sm" class="button-view mb-1 mb-sm-0"  variant="button-view" @click="redirectContent(item.actions)" v-if="showButtonContent(item.actions)">
              <feather-icon icon="EyeIcon" size="18"/>
              {{$t('campaigns.watch_content')}} 
            </b-button>
          </div>
        </b-col>
        <b-pagination
          class="d-flex justify-content-center mt-1"
          v-model="current_page"
          :total-rows="items.length"
          :per-page="per_page"
        ></b-pagination>
      </b-row>       
    </b-col>
   
    <b-modal v-model="modal" size="md" centered hide-footer title-class="text-center-modal" :title="$t('campaigns.addContentModal')"  @change="hasChangedModal()">

      <b-row class="border-bottom">
        <b-col class="col-12 mb-1">
          <b-dropdown
              no-flip
              :text="$t(select_text_default)"
              :class="`w-md-75 w-100 dropdownNet ${select_text_default !== 'default_select_net' ? 'p-auto' : ''}`"
              v-model="selected_net"
              variant="outline"
              menu-class="submenu"
          >
            <template #button-content>
              <b-img 
                :src="utils.getDropdownNetworks().find((item) => item.value === select_text_default.toLowerCase()).icon" 
                class="dropdown-icon-invitations"
                v-if="select_text_default !== 'default_select_net'"/>
              <span>{{$t(select_text_default)}}</span>
            </template>
              <b-dropdown-item v-on:click="dropdownItemClick(item)" v-for="item in utils.getDropdownNetworks()" :key="item.value">
              <b-img :src="item.icon" class="iconDropdown"></b-img>
              <span > {{ item.network }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </b-col>

        <b-col class="col-12 mb-1">
          <v-select v-model="selected_content_type" :options="getTypeContent(selected_net)"/>
        </b-col>

        <b-col class="col-12 mb-1">
          <b-form-datepicker
            :placeholder="$t('campaigns.date_publish')"
            v-model="date_publish"
            class="mb-2"
            :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit'}"
          ></b-form-datepicker>
        </b-col>
      </b-row>
      <general-form
        v-if="selected_content_type !== 'Selecciona un contenido'"
        :content="(Object.keys(content_selected).length === 0) ? content_to_add : content_selected"
        :uuids="{campaign_uuid, campaign_influencer}"
        :net="selected_net"
        :info_add="{date_publish, estimated_cost}"
        :content_type="selected_content_type.value"
        v-on:closeModal="okModal"
      > 
      </general-form>
    </b-modal>


    <b-sidebar
      right
      v-model="sidebar_details"
      backdrop-variant="dark"
      no-header-close
      backdrop
      shadow
      width="500px"
      body-class="m-1"
      bg-variant="white"
      v-if="show_details_content"
    >
      <template #header>
        <div class="header-sidebar d-flex mt-2">
          <b-img class="icon-size" :src="`${utils.getImageNetworkColor(show_details_content.network)}`"/>
          <div class="d-flex flex-column">
            <span class="title mb-0">{{$t(`invitation.${show_details_content.network}`)}} {{$t(`invitation.${show_details_content.content_type}`)}} #{{show_details_content.index}}</span>
            <span>{{$t('campaigns.date_publish')}}: {{formatDate(show_details_content.published_date)}}</span>
          </div>
          <feather-icon
              class="close-component close-right"
              icon="XIcon"
              size="16"
              @click="closeSidebar"
          />
        </div>
      </template>
      <details-content 
        :key="change_detail_content" 
        @closeSidebar="closeSidebar" 
        :content="show_details_content" 
        :campaign="campaign" 
        v-if="sidebar_details"
        @updateContent="updateContent"
      />
    </b-sidebar>
  </div>
</template>

<script>
import { BButton, BPagination, BImg, BCol, BRow, BDropdown, BDropdownItem, BFormDatepicker, BSidebar } from 'bootstrap-vue';
import GeneralForm from './forms/GeneralForm.vue'
import vSelect from 'vue-select';
import utils from '@/libs/utils';
import detailsContent from './detailsContent.vue';
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  data() {
    return {
      contents: [],
      campaign_uuid: '',
      campaign_influencer: '',
      utils,
      items: [],
      modal: false,
      content_selected: {},
      current_page: 1,
      per_page: 10,
      type_contents : [
        {
          value: null,
          type: [{ value: null, label: "Selecciona una red primero" }],
        },
        {
          value: "instagram",
          type: [
            { value: "story", label: "Story" },
            { value: "photo", label: "Foto" },
            { value: "reel", label: "Reel" },
            { value: "video", label: "Video" },
            { value: "streaming", label: "Live stream" },
          ],
        },
        {
          value: "youtube",
          type: [
            { value: "video", label: "Video" },
            { value: "streaming", label: "Live stream" },
            { value: "reel", label: "Short" },
          ],
        },
        {
          value: "tiktok",
          type: [
            { value: "reel", label: "TikTok" },
            { value: "story", label: "Story" },
            { value: "streaming", label: "Live stream" },
          ],
        },
        {
          value: "facebook",
          type: [
            { value: "post", label: "Post" },
            { value: "photo", label: "Foto" },
            { value: "reel", label: "Reel" },
            { value: "video", label: "Video" },
            { value: "streaming", label: "Live stream" },
          ],
        },
        {
          value: "twitter",
          type: [
            { value: "post", label: "Post" },
            { value: "photo", label: "Foto" },
            { value: "reel", label: "Reel" },
            { value: "video", label: "Video" },
            { value: "streaming", label: "Live stream" },
          ],
        },
        {
          value: "linkedin",
          type: [
            { value: "post", label: "Post" },
            { value: "photo", label: "Foto" },
            { value: "video", label: "Video" },
            { value: "streaming", label: "Live stream" },
          ],
        },
        {
          value: "spotify",
          type: [{ value: "podcast", label: "Podcast" }],
        },
        {
          value: "twitch",
          type: [
            { value: "streaming", label: "Live stream" },
            { value: "clip", label: "Clip" }
          ],
        },
        {
          value: "pinterest",
          type: [
            { value: "photo", label: "Foto" },
            { value: "video", label: "Video" },
          ],
        },
        {
          value: "blog",
          type: [{ value: "post", label: "Blog Post" }],
        },
        {
          value: "snapchat",
          type: [
            { value: "story", label: "Story" },
            { value: "reel", label: "Spotlight" },
          ],
        },
      ],
      selected_net: null,
      select_text_default: 'default_select_net',
      selected_content_type: 'Selecciona un contenido',
      estimated_cost: null,
      date_publish: null,
      content_to_add : {
        content_type: null,
        image_content: null,
        number_of_views: null,
        url_info: null,
        number_of_prints: null,
        number_of_responses: null,
        number_of_clicks_to_the_link: null,
        number_of_likes: null,
        number_of_shares: null,
        scope_number: null,
        number_of_interactions: null,
        number_of_times_saved: null,
        number_of_comments: null,
        network: null
      },
      sidebar_details: false,
      show_details_content: null,
      change_detail_content: false,
    };
  },
  components: {
    GeneralForm,
    BFormDatepicker,
    BDropdown,
    BDropdownItem,
    BPagination,
    BButton,
    BImg,
    vSelect,
    BCol,
    BRow,
    BSidebar,
    detailsContent,
  },
  props: {
    campaign: {
      required: true,
      type: Object,
    },
  },
  created() {
    this.contents = this.campaign.campaign_influencer.content;
    this.campaign_uuid = this.campaign.uuid;
    this.campaign_influencer = this.campaign.campaign_influencer.uuid
    this.getContentsTable();
  },

  methods: {
    updateContent(data) {
      const new_contents = this.contents.map((item) => {
        if (item.uuid === data.uuid) item = data;
        return item;
      });
      this.contents = new_contents;
      this.getContentsTable();
    },
    itemsFiltered() {
      return this.items.slice((this.current_page - 1) * this.per_page, this.current_page * this.per_page)
    },
    verifyTYC() {
      if (this.campaign.campaign_influencer.accept_tyc) {
        this.addContent();
      } else {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: 'Error',
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: this.$t('invitation.first_accept_tyc')
          }
        });
      }
    },
    closeSidebar() {
      this.sidebar_details = false
      this.show_details_content = null
      this.change_detail_content = !this.change_detail_content
    },
    showDetails(data) {
      this.sidebar_details = true
      this.show_details_content = data
      this.change_detail_content = !this.change_detail_content
    },
    getContentType(content) {
      if (content.network === 'tiktok' && content.content_type === 'reel') return 'TikTok'
      if (content.network === 'blog' && content.content_type === 'post') return 'Blog Post'
      if (content.network === 'snapchat' && content.content_type === 'reel') return 'Spotlight'
      return this.$t(`invitation.${content.content_type}`)
    },
    changeValue(new_value) {
      this.estimated_cost = new_value
    },
    formatDate(date_string, has_timezone = false) {
      if (!date_string) return '-'
      const date = new Date(date_string)
      if (!has_timezone) date.setDate(date.getDate() + 1)
      return utils.getDatev2(date)
    },
    showButtonContent(content) {
      return content.url_info || content.image_content
    },
    redirectContent(content) {
      if (content.url_info) {
        window.open(content.url_info, '_blank')
      } else if (content.image_content) {
        window.open(content.image_content, '_blank')
      }
    },
    resetValues() {
      this.selected_net = null;
      this.select_text_default = 'default_select_net';
      this.selected_content_type = 'Selecciona un contenido';
      this.estimated_cost = null;
      this.date_publish = null;
      this.content_to_add = {
        image_content: null,
        number_of_views: null,
        url_info: null,
        number_of_prints: null,
        number_of_responses: null,
        number_of_clicks_to_the_link: null,
        number_of_likes: null,
        number_of_shares: null,
        scope_number: null,
        number_of_interactions: null,
        number_of_times_saved: null,
        number_of_comments: null,
        network: null
      }
      this.content_selected = {}
    },
    hasChangedModal() {
      this.resetValues();
      this.content_selected = {};
    },
    getContentsTable() {
      this.items = [];
      this.contents.forEach((element, index) => {
        element.index = index + 1
        this.items.push({ID: element.id, content_type: {content_type: element.content_type,  network: element.network}, published_date: element.published_date, last_update:element.updated_at, actions: element})
      })
    },
    dropdownItemClick(net) {
      this.selected_net = net.value;
      this.select_text_default = net.network;
      this.selected_content_type = this.type_contents.filter((item) => item.value === net.value)[0].type[0]
      this.content_to_add.network = net.value
    },
    getTypeContent(net) {
      return this.type_contents.filter((item) => item.value === net)[0].type
    },
    okModal(data) {
      this.modal = false;
      this.campaign.campaign_influencer.content.push(data)
      const element = this.items.find(item => item.actions.uuid === data.uuid)
      if (element) {
        element.index = data.index;
        element.actions = data
        element.content_type = data.content_type
        element.network = data.network
        element.published_date = data.published_date
        element.last_update = data.updated_at
      } else {
        data.index = this.items.length + 1;
        this.$emit('plusContent')
        this.items.push({
          content_type: {content_type: data.content_type,  network: data.network},
          published_date: data.published_date, 
          last_update: data.updated_at, 
          actions: data,
        })
      }

      this.resetValues();
    },
    addContent() {
      this.modal = true;
    },
    getIconNet() {
      return utils.getDropdownNetworks().filter(i => i.value === this.selected_net)[0].icon;
    },
  },
  watch: {
    selected_content_type(new_value) {
      this.content_to_add.content_type = new_value.value
    }
  }
};
</script>

<style>
.no-content {
  font-size: 30px;
  color: rgb(180, 180, 180);
}
.dropdown-disabled {
  border: 1px solid #D8D6DE;
  padding: 0.4em;
  border-radius: 0.3em;
}
.iconDropdown { 
  width: 27px; margin-right:5px; margin-top:-2px;
  top: 16px
}
.dropdownNet{
  border: 1px solid #D8D6DE;
  /* padding: 0.4em; */
  border-radius: 0.3em;
}
.dropdownNet:focus-within{
  border: none;
  border: 1px solid #7367F0;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;  
}
.dropdown-icon-invitations {
  width: 20px;
  margin-right: 1em;
}
.icon-networks-connected {
  font-size: 21px;
  color: #fff !important;
  padding: 10px;
}
.submenu {
  margin-top: 15em;
}
.icon-size {
  margin: 10px;
  width: 35px;
}
.icon-size-x {
  margin: 10px;
  width: 33px;
  height: 33px;
  padding: 2px;
  background: black;
  border-radius: 50%;
}

.content-type{
  font-size: 20px;
  margin-right: 10px;
  color:#7367F0;
}

.button-edit{ 
  color: #7367F0;
  border-color: #7367F0;
  margin-right: 10px;
}
.button-edit:hover{ 
  background-color: #e7e5ff ;
  color: #7367F0;
  border-color: #7367F0;
  margin-right: 10px;
}
.button-view{
  color: #28c76f;
  border-color:  #28c76f;
}
.button-view:hover{
  background:#effff6;
  color: #28c76f;
  border-color:  #28c76f;
}
.text-center-modal{
  margin-left: 135px;
}
.button-box-color-gray {
  background-color: #FAFAFA;
}
.header-sidebar {
  font-size: 1rem;
}
.header-sidebar .title{
  font-size: 14px;
  font-weight: 900;
}
.close-right {
  position: absolute;
  right: 50px;
}
</style>